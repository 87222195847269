<template>
  <div class="navbar-container d-flex content align-items-center">


    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center d-none d-lg-flex">
      <bookmarks :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </div>

    <work-timer class="wt-tracker" v-if="$can('edit', 'deal.tab_working_time_report')" ref="worktimer" />

    <b-navbar-nav class="nav align-items-center nav-right-tools">
      <locale />
      <dark-Toggler class="d-none d-lg-block" />
      <search-bar />
      <!--<cart-dropdown />-->
      <notification-dropdown />
      <user-dropdown />
    </b-navbar-nav>

    <file-preview-modal></file-preview-modal>

  </div>
</template>

<script>

import {
  BLink, BNavbarNav,
} from 'bootstrap-vue'
import WorkTimer from '@src/views/pages/workingtime/worktimer.vue'
import Bookmarks from '@src/views/includes/Bookmarks.vue'
import Locale from '@src/views/includes/Locale.vue'
import SearchBar from '@src/views/components/searchbar/SearchBar.vue'
import DarkToggler from '@src/views/includes/DarkToggler.vue'
import NotificationDropdown from '@src/views/includes/NotificationDropdown.vue'
import UserDropdown from './../user-dropdown/userDropdown.vue'
import filePreviewModal from './filePreviewModal.vue'


export default {
  components: {
    BLink,
      filePreviewModal,
    BNavbarNav,
    Bookmarks,
    Locale,
    SearchBar,
    DarkToggler,
    NotificationDropdown,
    UserDropdown,
    WorkTimer
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
    mounted(){

        this.$root.$refs['worktimer'] = this.$refs['worktimer'];

    }
}
</script>
