<template>
  <div
    id="timer-inline"  ref="global-button"
    class="tooltipstered"
  >
    <div class=" btn-panel">
      <button
        v-if="!showContinueBtns"
        id="timer-start-btn"
        type="button"
        class="btn btn-outline-primary btn-sm"
        @click="initTimerBtnClick"
      >
        <feather-icon
          icon="PlayIcon"
          size="16"
        />
      </button>
      <button
        v-if="showContinueBtns"
        id="timer-pause-btn"
        ripple
        type="button"
        class=" btn btn-outline-primary btn-sm"
        @click="playPauseBtnClick"
      >
        <feather-icon
          :icon="(is_run)?'PlayIcon':'PauseIcon'"
          size="16"
        />
      </button>
      <button
        v-if="showContinueBtns"
        id="timer-stop-btn"
        type="button"
        class=" btn btn-outline-primary btn-sm"
        @click="stopBtnClick"
      >
        <feather-icon
          icon="StopCircleIcon"
          size="16"
        />
      </button>
    </div>
    <div
      v-if="showContinueBtns"
      id="timer-score-wrap"
      class="time-info "
    >

      <div class="user-timer badge  text-primary">
        <!--<feather-icon-->
                <!--icon="ClockIcon"-->
                <!--color="gray"-->
                <!--size="16"-->
        <!--/>-->
        <span
          id="timer-score"
          v-b-tooltip.hover.top="timer_tooltip"
        >{{ timeScore }}</span>
      </div>
    </div>

    <working-time-modal @clear-action="timerAction = ''" :timer-action="timerAction"></working-time-modal>
  </div>
</template>

<script>

    import {VBTooltip} from 'bootstrap-vue'
    import workingTimeModal from '../deal/modals/processWorkingTimeModal'
    import {AGREEMENT_DYNAMIC_STORE_NAME,  WORKING_TIME_PREFIX as PREFIX} from '../deal/moduleHelper'

    export default {

        directives: {
            'b-tooltip': VBTooltip,
        },
        components: {
            workingTimeModal
        },
        data() {
            return {
                PREFIX,
                tab_timer_id: false,
                // mainTabIndex: '',
                timerData: {},
                timeScore: '00:00:00',
                showContinueBtns:false,
                is_run: false,
                timer_tooltip: 'Tooltip on top!',
                timerAction:'',
            }
        },
        created() {
            // alert(this.$root.mainTabIndex);
            // this.mainTabIndex = Date.now();
            // localStorage.setItem('t-main', this.mainTabIndex);
            this.initEvents();
        },
        mounted(){
            if(this.authUser()){
                this.displayTimer();
            }
        },
        methods: {
            displayTimer(setMain) {
                let self = this;
                if (setMain !== false) {
                    localStorage.setItem('t-main', this.$root.mainTabIndex);
                }
                if(localStorage.hasOwnProperty('userData')) {
                    this.getUserTimerData().then((resp) => {

                        self.timerData = resp;

                        this.setScore(this.getScore());

                        if (this.isTimerRan()) {
                            this.updateUserTimerData({}, 'reloading');
                            this.runTimer(false);
                        } else if(this.isTimerStopped()){
                            this.stopTimer(false);
                        } else if(this.isTimerReset()){
                            this.resetTimer(false);
                        }
                        this.setScoreTooltip();
                    });
                }

            },
            getUserTimerData() {

                return new Promise((resolve, reject) => {
                    this.async('get', '/user_workingtime_tracker', {}, function (resp) {

                        return resolve(resp.data);

                    }, function (error) {
                        return reject(error)
                    });
                })

            },
            updateUserTimerData(timerData, action, record_id) {
                if(localStorage.hasOwnProperty('userData')){
                    this.async('post', '/workingtime/api_process_timer_data', {
                        timer_data: timerData,
                        action: action,
                        record_id: record_id ? record_id : 0
                    }, function (data) {
                        // if (data.error) {
                        //     this.$toast.error(data.error);
                        // }
                        if (action != 'reloading' && action != 'start') {
                            this.reloadTables();
                        }
                        if (action == 'complete' || action == 'complete_and_move_to_not_calc') {
                            this.resetTimer();
                        } else if (action == 'rerun') {
                            this.timerData = data.data;
                            this.runTimer();
                            this.setScoreTooltip();
                        } else if (action == 'pause') {
                            this.setScore(data.data.data.score);
                            this.renderScore(data.data.data.score);
                        }
                    })
                } else {
                    clearInterval(this.tab_timer_id);
                }

            },
            setScore(val) {

                val = parseInt(val);

                this.timerData.score = val;

                if (this.isMainTab()) {

                    if (val % 60 === 0) { // every minute update time on server

                        this.updateUserTimerData({}, 'reloading');
                    }

                    localStorage.setItem('t-score', val);
                }

            },
            getScore() {

                let score;
                if (!this.isMainTab()) {
                    score = localStorage.getItem('t-score');
                } else {
                    score = this.timerData.score;
                }

                return score ? score : 0;
            },
            updateScore() {
                let score = this.getScore();

                score++;
                this.setScore(score);
                this.renderScore(score);
            },
            renderScore(score) {

                let h = Math.floor(score / 3600);
                let m = Math.floor(score % 3600 / 60);
                let s = Math.floor(score % 3600 % 60);

                this.timeScore = ((h < 10? '0'+h : h) + ':' + (m < 10? '0'+m : m) + ':' + (s < 10? '0'+s : s));
            },
            getTimerData() {
                // return this.timerData.data;
                return this.timerData;
            },
            updateInnerTimerData(newData){
                this.timerData = newData;
            },
            isMainTab() {
                return localStorage.getItem('t-main') == this.$root.mainTabIndex;
            },
            isTimerRan() {
                if (!this.isMainTab()) {
                    return localStorage.getItem('t-status') === 'works';
                }
                return this.timerData.tracker_status === 'works';
            },
            isTimerReset() {
                return  this.timerData.tracker_status !== 'works' && this.timerData.tracker_status !== 'stopped';
            },

            isTimerStopped() {
                if(!this.isMainTab()) {
                    return localStorage.getItem('t-status') === 'stopped' ;
                }
                return  this.timerData.tracker_status === 'stopped';
            },
            reloadTables() {

                    if(this.$root.$refs[this.PREFIX+'_TAB']){

                        if(this.$root.$refs[this.PREFIX+'_TAB'].displayed_table == 'real_time') {
                            this.$root.$refs[this.PREFIX+'_TAB'].refreshDataTable();
                        } else {
                            this.$root.$refs[this.PREFIX+'_TAB'].displayed_table = 'real_time';
                        }

                    }
            },
            runTimer(ajax) {

                clearInterval(this.tab_timer_id);

                this.setTimerStatus('works');
                this.tab_timer_id = setInterval(() => this.updateScore(), 1000);
                this.showBtnsForContinue();
                this.updatePauseIcon();
                if(ajax){
                    this.updateUserTimerData(this.timerData, 'start');
                }
            },
            setTimerStatus(status) {
                if(this.isMainTab()) {
                    localStorage.setItem('t-status', status);
                }
                this.timerData.tracker_status = status;
            },
            showBtnsForContinue() {
                this.showContinueBtns = true;
            },
            hideBtnsForContinue() {
                this.showContinueBtns = false;
            },
            updatePauseIcon() {
                if (!this.isTimerRan()) {
                    this.is_run = true;
                } else {
                    this.is_run = false;
                }
            },
            initEvents() {

                window.onbeforeunload = function() { // page reloading works after user clicked page
                    localStorage.removeItem('t-main');
                };

                this.syncAllTabs(this);
            },
            initTimerBtnClick () {
                localStorage.setItem('t-main', this.$root.mainTabIndex);
                this.$bvModal.show(this.PREFIX+'-modal');
                this.timerAction = 'run_new';
                // this.openWorkingTimeReportModal(false, false, true, 'run_new');
            },
            playPauseBtnClick(){
                localStorage.setItem('t-main', this.$root.mainTabIndex);
                if (this.isTimerRan()) {
                    this.stopTimer(true);
                } else if(this.isTimerStopped()){
                    this.runTimer(true);
                }
            },

            stopBtnClick () {
                localStorage.setItem('t-main', this.$root.mainTabIndex);
                this.$bvModal.show(this.PREFIX+'-modal');
                this.timerAction = 'complete';
            },

            stopTimer(ajax, complete, moveToNotCalculated) {

                clearInterval(this.tab_timer_id);

                this.setTimerStatus('stopped');
                this.showBtnsForContinue();
                this.updatePauseIcon();

                this.renderScore(this.getScore());

                if(ajax){
                    let action = complete?'complete':'pause';
                    if(moveToNotCalculated) {
                        action = 'complete_and_move_to_not_calc'
                    }
                    this.updateUserTimerData(this.timerData, action, this.timerData.client_working_time_id);
                }

            },
            syncAllTabs(){
                let self = this;
                setInterval(function(){

                    if(!localStorage.getItem('t-main')){

                        localStorage.setItem('t-main', self.$root.mainTabIndex);
                        if(self.isMainTab()){
                            self.displayTimer(false);
                        }
                    }

                    if(!self.isMainTab()){
                        self.syncTimerWithMainTab();

                    } else {

                    }

                }, 1000)
            },
            setScoreTooltip() {

                let data = this.getTimerData();

                if (data && data.name_activity) {
                    this.timer_tooltip = data.name_activity;
                } else {
                    this.timer_tooltip = 'Nie ustawiony';
                }
            },
            syncTimerWithMainTab(){
                let mainTabStatus = localStorage.getItem('t-status');
                if (mainTabStatus == 'works') {

                    this.setTimerStatus('works');
                    this.updateScore();
                    this.showBtnsForContinue();
                    this.updatePauseIcon();
                } else if(mainTabStatus == 'stopped'){

                    this.stopTimer(false);
                } else{
                    this.resetTimer(false);
                }
            },

            resetTimer(ajax, delete_record_id) {

                clearInterval(this.tab_timer_id);

                this.tab_timer_id = false;
                this.timerData.score = 0;
                this.timerData.client_working_time_id = '';
                this.timerData.name_activity = '';
                // this.timerData.data = '';
                this.setTimerStatus('not_set');

                if(ajax){
                    this.updateUserTimerData(this.timerData, 'deleting', delete_record_id);
                }

                // this.setScoreTooltip();
                this.hideBtnsForContinue();
            },
            startFreshTimerFromRecord(record_id) {

                this.updateUserTimerData({}, 'rerun', record_id);

            }



        }
    }
</script>


