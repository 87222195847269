<template>
  <b-navbar-nav class="nav">
    <!-- Toggler Button -->
    <b-nav-item>
      <div class="nav-link modern-nav-toggle">

        <feather-icon
          :icon="collapseTogglerIconFeather"
          size="20"
          class="d-none d-xl-block collapse-toggle-icon"
          @click="toggleCollapsed"
        />
      </div>
    </b-nav-item>

    <b-nav-item
            v-if="$can('edit', 'agreement.tab_tasks') || $can('edit', 'deal.tab_tasks')"
            @click="$bvModal.show('global-task-modal')"
    >
      <feather-icon
        id="bookmark-task"
        icon="CheckSquareIcon"
        size="21"
      />
      <b-tooltip
        triggers="hover"
        target="bookmark-task"
        :title="$t('label_add') + ' ' +$t('label_task').toLowerCase()"
        :delay="{ show: 300, hide: 50 }"
      />
    </b-nav-item>

    <b-nav-item
            v-if="$can('edit', 'contact.tab_meetings')"
            @click="$bvModal.show('global-meeting-modal')"
    >
      <feather-icon
              id="bookmark-meeting"
              icon="MessageCircleIcon"
              size="21"
      />
      <b-tooltip
              triggers="hover"
              target="bookmark-meeting"
              :title="$t('label_add') + ' ' +$t('label_meeting').toLowerCase()"
              :delay="{ show: 300, hide: 50 }"
      />
    </b-nav-item>

    <b-nav-item
            v-if="$can('edit', 'deal.tab_emails')"
            @click="$bvModal.show('global-email-modal')"
    >
      <feather-icon
              id="bookmark-email"
              icon="MailIcon"
              size="21"
      />
      <b-tooltip
              triggers="hover"
              target="bookmark-email"
              :title="$t('label_add') + ' ' +$t('label_email').toLowerCase()"
              :delay="{ show: 300, hide: 50 }"
      />
    </b-nav-item>

    <process-task-modal
            modal-ref="global-task-modal"
            show-all="true"
            :is-deal="false"
            :module-item="false"
            :edited-item="false"
            @item-added="$root.$refs['task_CONTENT_TAB'] ? $root.$refs['task_CONTENT_TAB'].refreshDataTable() : false"
    ></process-task-modal>

    <process-meeting-modal
            modal-ref="global-meeting-modal"
            show-all="true"
            :module-item="false"
            :edited-item="false"
            @item-added="$root.$refs['meeting_CONTENT_TAB'] ? $root.$refs['meeting_CONTENT_TAB'].refreshDataTable() : false"
    ></process-meeting-modal>

    <process-email-send-modal
            modal-ref="global-email-modal"
            show-all="true"
            :module-item="false"
            :edited-item="false"
            @item-added="$root.$refs['email_CONTENT_TAB'] ? $root.$refs['email_CONTENT_TAB'].refreshDataTable() : false"
    ></process-email-send-modal>


  </b-navbar-nav>
</template>

<script>

import {
  BNavbarNav, BNavItem, BTooltip,
} from 'bootstrap-vue'

import processTaskModal from '@src/views/pages/agreement/modals/processTaskModal'
import processMeetingModal from '@src/views/pages/contact/modals/processMeetingModal'
import processEmailSendModal from '@src/views/pages/deal/modals/processEmailSendModal'

import { computed } from '@vue/composition-api'
import useVerticalNavMenu from '@core/layouts/layout-vertical/components/vertical-nav-menu/useVerticalNavMenu'

export default {
  components: {
    BNavbarNav, BNavItem, BTooltip,
      processTaskModal,
      processMeetingModal,
      processEmailSendModal
  },
    props: {
        toggleVerticalMenuActive: {
            type: Function,
            default: () => {},
        },
    },
  setup(props) {

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

      const {
          collapseTogglerIcon,
          toggleCollapsed,
      } = useVerticalNavMenu(props)

      const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    return {

      perfectScrollbarSettings,
        collapseTogglerIconFeather,
        toggleCollapsed
    }
  },


}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

ul
{
  list-style: none;
  padding: 0;
  margin: 0;
}
p {
  margin: 0;
}

.nav-bookmar-content-overlay {
    position: fixed;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-transition: all 0.7s;
    transition: all 0.7s;
    z-index: -1;

    &:not(.show) {
      pointer-events: none;
    }

    &.show {
      cursor: pointer;
      z-index: 10;
      opacity: 1;
    }
}
</style>
