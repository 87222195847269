<template>
    <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="search-list search-list-main scroll-area overflow-hidden"
            :tagname="tagname? tagname : 'div'"
    >
        <ul :style="{height:( items_length < 1? 'auto' : '400px')}"  class="hovered-list-container">
            <li  v-for="(suggestion_list, grp_name, grp_index) in items" >


                <!-- Group Header -->
                <p class="suggestion-group-title">
            <span>
              {{ $t(grp_name == 'mails'? 'label_mail': 'label_'+ grp_name) }}
            </span>
                </p>

                <!-- Suggestion List of each group -->
                <ul >
                    <li
                            v-for="(suggestion, index) in suggestion_list"
                            :key="index"
                            class="suggestion-group-suggestion cursor-pointer"
                            :class="{'suggestion-current-selected': currentSelected === `${grp_index}.${index}`}"
                            @mouseenter="currentSelected = `${grp_index}.${index}`"
                            @mousedown.prevent="closeBar()"
                    >

                        <!--@mousedown.prevent=""-->
                        <!--@mousedown.prevent="suggestionSelected(grp_name, suggestion)"-->
                        <!--v-if="grp_name === 'pages'"-->
                        <b-link  :to="solveRoute(suggestion)"

                                class="p-0 d-block"
                        >
                            <feather-icon
                                    :icon="suggestion.icon"
                                    class="mr-75"
                            />
                            <span class="align-middle">{{ suggestion.text.replace(/<\/?[^>]+(>|$)/g, "") }}</span>
                        </b-link>

                    </li>

                    <!--<li-->
                            <!--v-if="!suggestion_list.length && searchQuery"-->
                            <!--class="suggestion-group-suggestion no-results"-->
                    <!--&gt;-->
                        <!--<p>$t('label_no_items')</p>-->
                    <!--</li>-->
                </ul>

            </li>
            <li ref="load" class="loader text-center" v-show="first_init || hasNextPage">
                {{$t('label_loading')}}...
            </li>
            <li v-if="!first_init && items_length< 1" class="no-results" :class="{'show':  items_length< 1}">
                <div class="text-center my-2">{{$t('label_no_items')}}</div>
            </li>
        </ul>


    </vue-perfect-scrollbar>

</template>

<script>

    import VuePerfectScrollbar from 'vue-perfect-scrollbar'
    import {
         BLink
    } from 'bootstrap-vue'

    export default {
        components:{
            VuePerfectScrollbar,
            BLink
        },

        props: ['url', 'queryParams', 'refreshList', 'tagname', 'searchQuery'],

        name: "InfiniteList",

        data: () => ({
            perfectScrollbarSettings: {
                maxScrollbarLength: 100,
            },
            first_init: true,
            total:0,
            items:{},
            items_length:false,
            observer: null,
            limit: 50,
            page:1,
            // search: '',
            getting_data: false,
            disableWatchSearch:false,
            // defaultItemWasShown:false,
            currentSelected: -1,
        }),

        computed: {
            hasNextPage () {
                return this.items_length < this.total;
            },
        },
        watch:{
            refreshList: function(newVal){
                if(newVal) {
                    this.init();
                    this.$emit('update:refreshList',false);
                }
            }
        },
        methods: {

            async infiniteScroll ([{isIntersecting, target}]) {

                if (isIntersecting) {
                    const ul = target.offsetParent; //getting closest parent with position relative/absolute
                    const scrollTop = target.offsetParent.scrollTop;//(сколько пикселей отступил от топа скрол) прокручено за пределы родителя //Получаем количество прокрученных пикселей

                    await this.getData();
                    await this.$nextTick();
                    ul.scrollTop = scrollTop;
                    this.observer.disconnect();
                    if (this.hasNextPage) {
                        this.observer.observe(this.$refs.load)
                    }
                }
            },
            getData(fresh){
                if(this.getting_data) { return;}
                if(fresh){
                    this.page = 1;
                    this.total = 0;
                    this.items ={};
                }
                this.getting_data = true;
                let params = {
                    length: this.limit,
                    start: (this.page - 1) * this.limit,
                    search:this.searchQuery
                };
                if(this.queryParams){
                    params = Object.assign(params, this.queryParams);
                }

               return new Promise((resolve, reject) => {
                   this.async('get',this.url, {params: params}, function (resp) {

                       // this.items = this.items.concat(this.prepareData(resp.data));
                       this.total = this.prepareTotals(resp.data.counts);
                       this.items = this.prepareData(resp.data.data);

                       this.page+=1;


                       this.getting_data = false;

                        this.first_init = false;

                       return resolve(resp);

                   });
               });
            },

            // search: _.debounce((loading, search, vm) => {
            //     fetch(
            //         `https://api.github.com/search/repositories?q=${escape(search)}`
            //     ).then(res => {
            //         res.json().then(json => (vm.options = json.items));
            //         loading(false);
            //     });
            // }, 350)
            async init(){
                await this.getData('fresh');
                if (this.hasNextPage) {

                    await this.$nextTick();

                    this.observer.observe(this.$refs.load)
                }
            },
            prepareData(data){
                let prepared = this.items;

                let total = this.items_length;
                for(let i = 0; i<data.length; i++){
                    if(!prepared[data[i].table_alias]){
                        prepared[data[i].table_alias] = [];
                    }
                    prepared[data[i].table_alias].push(data[i]);
                    total++;
                }
                this.items_length = total;

                return prepared;
            },
            prepareTotals(counts){
                let prepared = 0;
                for(let prop  in counts) {
                    prepared+=parseInt(counts[prop])
                }
                return prepared;
            },
            closeBar(){
                let self = this;
                setTimeout(function(){
                    self.$emit('close-bar');
                },200);

            },
            solveRoute(item){
                let route = {name: 'agreements', params:{}};
                let routeName = '';
                let params = {};
                switch(item.table_alias){
                    case 'contacts': routeName = 'contact-details'; params.id = item.value; break;
                    case 'deals': routeName = 'deal-details'; params.id = item.value; break;
                    case 'agreements': routeName = 'agreement-details'; params.id = item.value; break;
                    case 'mails': routeName = 'mail_edit'; params.id = item.value; break;
                    case 'receivables': routeName = 'agreement_tabs'; params.alias = 'payment'; params.id = item.value; break;
                    case 'tasks': routeName = 'agreement_task_details';  params.id = item.value; break;
                    case 'procurations': routeName = 'procurations'; break;
                    case 'documents': routeName = 'agreement_tabs'; params.alias = 'document'; params.id = item.value; break;
                }

                route = {name: routeName, params: params};
                return route;
            }
        },

        mounted () {

            this.observer = new IntersectionObserver(this.infiniteScroll, {threshold: 0});
            // this.init();
            // this.observer = new IntersectionObserver(this.infiniteScroll);
        },
        beforeDestroy(){
            this.observer.disconnect();
        }
    }
</script>


<style lang="scss" scoped>
    @import '~@core/scss/base/bootstrap-extended/include';
    @import '~@core/scss/base/components/variables-dark';

    ul
    {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    p {
        margin: 0;
    }

    /* .app-auto-suggest {
      position: relative;
    }

    .auto-suggest-suggestions-list {
      box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
      border-radius: 6px;
      position: absolute;
      top: calc(100% + 1rem);
      background-color: white;
      width: 100%;
    } */

    .suggestion-group-title {
        font-weight: 500;
        padding: .75rem 1rem .25rem;
    }

    .suggestion-group-suggestion {
        padding: .75rem 1rem
    }

    .suggestion-current-selected {
        background-color: $body-bg;

        .dark-layout & {
            background-color: $theme-dark-body-bg;
        }

    }
    @import '@core/scss/base/bootstrap-extended/include';

    .hovered-list {
        position: relative;
        height: calc(100% - calc(3.49rem + 3.32rem));
        .hovered-list-container {
            padding: 0;
            margin: 0;

            li {
                cursor: pointer;
                transition: all 0.2s, background 0s, border-color 0s, color 0s;
                animation: fadeIn 0.5s linear;
                animation-fill-mode: both;
                position: relative;
                background: white;
                &.media {
                    padding: ($spacer + 0.5);
                    &:hover {
                        transform: translateY(-2px);
                        box-shadow: 0 3px 10px 0 $border-color;
                        transition: all 0.2s;
                        z-index: 1;
                    }
                    .media-left {
                        display: flex;
                        flex-direction: column;
                    }
                    .media-body {
                        overflow: hidden;
                    }
                }

                &.mail-read {
                    background-color: $white;
                }

                &.selected-row-bg {
                    background-color: rgba($primary, 0.06);
                    &:not(:first-child) {
                        border-color: #e4e1e8;
                    }
                }
                &:not(:first-child) {
                    border-top: 1px solid $border-color;
                }
                .mail-details {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 0.55rem;
                }
                .mail-date {
                    color: $text-muted;
                    font-size: 0.857rem;
                }
                .mail-message {
                    p {
                        color: $text-muted;
                    }
                }
            }

        }
        .no-results {
            display: none;
            padding: 1.5rem;
            text-align: center;
            &.show {
                display: block;
            }
        }
        .ps__rail-y {
            z-index: 2;
        }
    }

</style>