<template>
    <!--static-->
    <b-modal
            :id="PREFIX + '-modal'"
            hide-footer
            @hide="resetForm()"
            @shown="initData()"
            size="md"
    >

        <template #modal-title>
            {{action == 'editing' ? $t('label_preview') : $t('label_preview')}}
        </template>

        <template #default="{ hide }">

            <validation-observer
                    tag="form"
                    class="p-1"
                    #default="{handleSubmit, reset, invalid}"
                    :ref="PREFIX + '_FORM'"

            >

                <div style="width: 95%;min-height: 300px;margin: 30px auto 30px auto;position:relative"
                     >

                    <iframe v-show="showIframe" id="previewer-frame" style="width:100%;height:100%;min-height: 500px" :src="fileSrc" ref="pdf-previewer"
                            name="previewer-frame">
                    </iframe>

                    <div v-show="!showIframe" style="border: 1px solid whitesmoke;padding: 10px; text-align: center">
                        <img  style="max-width: 100%; max-height: 400px; margin:0 auto; display: inline-block" :src="fileSrc" alt="">
                    </div>
                </div>


                <div class="d-flex justify-content-end mt-2">
                    <button @click.prevent="$bvModal.hide(PREFIX + '-modal')" type="reset" class=" btn btn-default">
                        {{$t('label_close')}}
                    </button>

                    <button @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)" type="submit"
                            class="btn btn-with-icon btn-primary waves-effect waves-light"><feather-icon style="position: relative; top:-1px" icon="DownloadIcon"/> {{(action ==
                        'editing')?$t('label_download'):$t('label_download')}}
                    </button>
                </div>

            </validation-observer>

        </template>

    </b-modal>
</template>


<script>
    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import {Base64} from '@src/utils/utils.js'

    import {
        BFormTextarea,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BInputGroupAppend,
        BInputGroup,
        BFormCheckbox,
        BInputGroupPrepend,
    } from 'bootstrap-vue'

    // import { NOTE_PREFIX as PREFIX, AGREEMENT_PREFIX, DEAL_PREFIX} from '../pages/agreement/moduleHelper'

    export default {
        components: {
            BFormGroup,
            BFormInput,
            BFormInvalidFeedback,
            BFormTextarea,
            BInputGroup,
            BInputGroupAppend,
            BFormCheckbox,
            BInputGroupPrepend,

            ValidationProvider,
            ValidationObserver,
            // vSelect
        },
        // props: ['moduleItem', 'editedItem', 'isDeal'],
        data() {
            return {

                PREFIX: 'preview-file',

                action: 'adding',

                fileSrc: '',
                showIframe: false,
                file: null,
                download_base_url: ''

            }
        },
// created(){console.log(Base64);},
        methods: {
            initData() {

                        this.file = this.$store.state.app.viewableFile;

                       this.showByUrl();


               //  let style = document.createElement("style");
               //  style.append( `<style type="text/css">
               //          img{
               //            max-width: 100%;
               //            display: inline-block;
               //            margin:0 auto;
               //          }
               //      </style>`);
               //  frames['pdf-previewer'].document.head.appendChild(style);
               //
               // let iframe = frames['pdf-previewer'].document;
               //  var css = '' +
               //      '<style type="text/css">' +
               //      'body{margin:0;padding:0;background:transparent}' +
               //      '</style>';
               //  iframe.open();
               //  iframe.write(css);
               //  iframe.close();

                // if (this.editedItem) {
                //     this.action = 'editing';
                //     let item = Object.assign({}, this.blankItemData);
                //     item.id = this.editedItem.id;
                //     for (let prop in item) {
                //         if (this.editedItem.hasOwnProperty(prop)) {
                //             item[prop] = this.editedItem[prop];
                //         }
                //     }
                //
                //     this.itemData = item;
                // } else {
                //     this.action = 'adding';
                //     this.itemData = Object.assign({}, this.blankItemData);
                //
                // }
            },

            showByUrl() {
                let fileUrl = this.$domain + this.file.dir + this.file.file_name;
                let extension = fileUrl.split('.').pop();

                if (extension == 'pdf') {
                    this.fileSrc = fileUrl;
                    this.showIframe = true;
                } else if (extension == 'jpg' || extension == 'jpeg' || extension == 'gif' || extension == 'png'){
                    this.fileSrc = fileUrl;
                    this.showIframe = false;
                }else{
                    // this.fileSrc = "https://docs.google.com/viewer?url=" + fileUrl + "&embedded=true&chrome=true&a=gt";
                    // this.fileSrc = "https://view.officeapps.live.com/op/embed.aspx?src="+fileUrl;
                    this.fileSrc = "https://docs.google.com/viewer?url="+encodeURI(fileUrl).replace(/%5B/g, '[').replace(/%5D/g, ']')+"&embedded=true";

                    this.showIframe = true;

                    // setInterval(function(){document.getElementById('previewer-frame').contentWindow.location.reload(true);}, 500);

                    this.checkIframeLoaded();
                }

            },
            checkIframeLoaded() {

                var iframe = document.getElementById('previewer-frame');
                var iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

                if (iframeDoc.readyState == 'complete') {
                    iframe.contentWindow.onload = function () { //not access crossorigin
                        alert("I am loaded");
                    };
                    // iframeDoc.location.href = url;
                    // console.log(iframeDoc.location,iframeDoc.location.protocol);
                    // console.log('cd',iframe.contentDocument);
                    return;
                }else {

                }

                window.setTimeout(this.checkIframeLoaded, 100);
            },

            resetForm() {
                this.fileSrc = '';
                this.file = false;
                this.$store.state.app.viewableFileSrc = '';
            },
            onSubmit() {
                // this.directHttp('GET', this.fileSrc, this.fileSrc.split('/').pop());
                let a = document.createElement('a');

                let href = this.$base_url + this.file.download_base_url + this.file.id ;

                if(this.file.hash || this.file.params) {
                    href+= '?';
                    if(this.file.hash){
                        href+= 'hash=' + this.file.hash + '&';

                    }
                    if(this.file.params) {
                        for(let prop in this.file.params) {
                            href+= prop + '=' + this.file.params[prop] + '&';
                        }
                    }
                   href =  href.substring(0, (href.length - 1));
                }
                a.href = href;
                a.download = this.fileSrc.split('/').pop();
                // a.target = '_blank';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);

            }
        },

    }
</script>